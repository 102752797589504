import { render, staticRenderFns } from "./GoodsSearchPreview.vue?vue&type=template&id=6a5c6048&scoped=true&"
import script from "./GoodsSearchPreview.vue?vue&type=script&lang=js&"
export * from "./GoodsSearchPreview.vue?vue&type=script&lang=js&"
import style0 from "./GoodsSearchPreview.vue?vue&type=style&index=0&id=6a5c6048&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a5c6048",
  null
  
)

export default component.exports